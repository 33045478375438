import React from "react"
import CaseResultsSlider from '../components/CaseResultsSlider'
import SidebarMenu from '../components/SidebarMenu'

const SpecialFAQSidebar = () =>{

    return(
<div>
<CaseResultsSlider />
<SidebarMenu />
</div>
    )
}
export default SpecialFAQSidebar